<!--
  - MyTh Ahmed Faiz Copyright © 2016-2022 All rights reserved.
  - Email: mythpe@gmail.com
  - Mobile: +966590470092
  - Website: https://www.4myth.com
  -->

<template>
  <app-card
    :title="$t('contactUs')"
  >
    <v-skeleton-loader
      v-if="skeleton"
      class="pa-2"
      type="article@2,text"
    />
    <v-container v-else>
      <v-row>
        <v-col cols="12">
          <div v-html="text" />
        </v-col>
        <v-col
          v-if="AppSettings.address"
          cols="12"
        >
          <v-toolbar
            class="mb-3"
            color="accent"
            dark
          >
            <v-toolbar-title>{{ parseAttribute('address') }}</v-toolbar-title>
          </v-toolbar>
          <div>{{ AppSettings.commercial_name }}</div>
          <div v-if="AppSettings.commercial_register">
            {{ parseAttribute('commercial_register') }}:&nbsp;{{ AppSettings.commercial_register }}
          </div>
          <div v-if="AppSettings.vat_number">
            {{ parseAttribute('vat_number') }}:&nbsp;{{ AppSettings.vat_number }}
          </div>
          <div
            v-html="AppSettings.address"
          />
        </v-col>

        <v-col
          order-md="2"
        >
          <v-row>
            <v-col cols="12">
              <span class="title">{{ parseAttribute('contact_data') }}</span>
            </v-col>
          </v-row>
          <v-row
            v-for="e in customer_services.filter(elm => Boolean(AppSettings[elm.value]))"
            :key="e.value"
            dense
          >
            <v-col cols="auto">
              <v-btn
                :href="e.mail ? 'mailto:' : 'tel:'+`${AppSettings[e.value]}`"
                target="_blank"
                text
              >
                <v-icon left>
                  {{ e.icon }}
                </v-icon>
                <p class="mb-0 ltr">
                  {{ AppSettings[e.value] }}
                </p>
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
        <v-col
          v-if="AppSettings.google_map_url"
          cols="12"
          sm="5"
        >
          <iframe
            :src="AppSettings.google_map_url"
            allowfullscreen=""
            height="280"
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
            style="border:0;"
            width="400"
          />
        </v-col>
      </v-row>
      <v-row
        v-if="Object.keys(AppSocials).length>0"
        class="mb-5"
        dense
        justify="center"
      >
        <v-col
          cols="12"
        >
          <v-toolbar
            class="my-5"
            color="accent"
            dark
          >
            <v-toolbar-title>{{ parseAttribute('follow_us') }}</v-toolbar-title>
          </v-toolbar>
        </v-col>
        <v-col
          v-for="(val,k) in AppSocials"
          :key="k"
          cols="auto"
        >
          <v-btn
            icon
            large
            @click="openWindow(val)"
          >
            <v-icon>mdi-{{ k }}</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </app-card>
</template>

<script>

import { MetaInfoMixin } from '@mixins'

export default {
  name: 'ContactUs',
  mixins: [MetaInfoMixin],
  data () {
    return {
      skeleton: !0,
      customer_services: [
        {
          text: 'customer_service_email',
          value: 'customer_service_email',
          icon: 'mdi-email',
          mail: !0
        },
        {
          text: 'customer_service_mobile',
          value: 'customer_service_mobile',
          icon: 'mdi-phone'
        },
        {
          text: 'customer_service_phone',
          value: 'customer_service_phone',
          icon: 'mdi-phone'
        }
      ]
    }
  },
  computed: {
    text () {
      return this.AppSettings.contact_us
    }
  },
  mounted () {
    (async () => {
      await this.fetchAppSettings()
      this.skeleton = !1
    })()
  }
}
</script>
